import React, { useEffect, useState } from 'react';
import '../styles/Profil.css';
import jwt_decode from 'jwt-decode';
import ToggleButton from './ToggleButton';


function Profil ({ updateUsername, updateProfileImage }) {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  
  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height * MAX_WIDTH) / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width * MAX_HEIGHT) / height);
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.5);
          resolve(compressedBase64);
        };
      };
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedBase64 = await compressImage(file);
        setPreviewUrl(compressedBase64);
        setEditedUserData(prev => ({
          ...prev,
          profileImage: compressedBase64
        }));
      } catch (error) {
        console.error('Erreur lors de la compression de l\'image:', error);
        alert('Erreur lors du traitement de l\'image');
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedUserData(userData);
  };

  const handleSave = () => {
    const token = localStorage.getItem('authToken');
    fetch(`/api/user/${userId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedUserData),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error('Erreur lors de la mise à jour');
      })
      .then((result) => {
        setIsEditing(false);
        setUserData(editedUserData);
        updateUsername(editedUserData.username);
        if (editedUserData.profileImage) {
          updateProfileImage(editedUserData.profileImage);
        }
      })
      .catch((error) => {
        console.error('Erreur:', error);
      });
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (!storedToken) {
      console.error('Token non trouvé');
      return;
    }

    try {
      const decodedToken = jwt_decode(storedToken);
      const decodedUserId = decodedToken.id;
      setUserId(decodedUserId);

      fetch(`/api/user/${decodedUserId}`, {
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error('Erreur lors de la récupération des données');
        })
        .then((data) => {
          setUserData(data);
          if (data.profileImage) {
            updateProfileImage(data.profileImage);
          }
        })
        .catch((error) => {
          console.error('Erreur:', error);
        });
    } catch (error) {
      console.error('Erreur lors du décodage du token:', error);
    }
  }, [updateProfileImage]);



  return (
    <>    
        
      <div className="backgroundPropos100">
        <div className="propos-item1">
          <ToggleButton
            title="Profil : modification"
            text={
              <div>
                {userData && (
                  <div className='Profil-flex'>
                    <p className="text-static">Nom d'utilisateur: {isEditing ? (
                      <span className="text-dynamic">
                        <input
                          type="text"
                          value={editedUserData.username}
                          onChange={(e) => setEditedUserData({ ...editedUserData, username: e.target.value })}
                        />
                      </span>
                    ) : (
                      <span className="text-dynamic">
                        {userData.username}
                      </span>
                    )
                    
                    }</p>
                    <p className="text-static">Manager ID: {isEditing ? (
                      <span className="text-dynamic">
                        <input
                          type="text"
                          value={editedUserData.id}
                          onChange={(e) => setEditedUserData({ ...editedUserData, id: e.target.value })}
                        />
                      </span>
                    ) : (
                      <span className="text-dynamic">
                        {userData.id}
                      </span>
                    )
                    
                    }</p>
                      <p className="text-static">Nom: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.name}
                  onChange={(e) => setEditedUserData({ ...editedUserData, name: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.name}
                </span>
              )}</p>
              <p className="text-static">Adresse: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.address}
                  onChange={(e) => setEditedUserData({ ...editedUserData, address: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.address}
                </span>
              )}</p>
              <p className="text-static">Code postal: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.postalCode}
                  onChange={(e) => setEditedUserData({ ...editedUserData, postalCode: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.postalCode}
                </span>
              )}</p>
              <p className="text-static">Ville: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.city}
                  onChange={(e) => setEditedUserData({ ...editedUserData, city: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.city}
                </span>
              )}</p>
              <p  className="text-static">Pays: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.country}
                  onChange={(e) => setEditedUserData({ ...editedUserData, country: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.country}
                </span>
              )}</p>
              <p className="text-static">Email: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.email}
                  onChange={(e) => setEditedUserData({ ...editedUserData, email: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.email}
                </span>
              )}</p>
               {/* Section image de profil */}
               <div className="profile-image-section"><p className="imagTaille">Attention image max 5 MB et max 800x800 px</p>
                      <img 
                        src={previewUrl || userData.profileImage || '/public/images/default-avatar.png'} 
                        alt="Photo de profil"
                        className="profile-image"
                      />
                      {isEditing && (
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="image-input"
                        />
                      )}
                    </div>

                    <div className="toggle-button-container">
                      {!isEditing ? (
                        <button className="toggle-button" onClick={handleEdit}>Modifier</button>
                      ) : (
                        <button className="toggle-button" onClick={handleSave}>Enregistrer</button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default Profil;
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { Alert, AlertDescription } from '../components/Alert';
import '../styles/AlertTokenExpires.css';

const useTokenCheck = () => {
  const navigate = useNavigate();
  const [tokenValid, setTokenValid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const WARNING_THRESHOLD = 5 * 60; // 5 minutes en secondes

  useEffect(() => {
    const checkTokenValidity = () => {
      const token = localStorage.getItem('authToken');
      
      if (!token) {
        setTokenValid(false);
        return;
      }

      try {
        const decodedToken = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        const timeUntilExpiry = decodedToken.exp - currentTime;
        
        if (decodedToken.exp < currentTime) {
          // Token expiré
          handleLogout();
        } else if (timeUntilExpiry <= WARNING_THRESHOLD) {
          // Afficher l'avertissement quand il reste moins de 5 minutes
          setShowWarning(true);
          setShowExpired(false);
          setTokenValid(true);
        } else {
          setTokenValid(true);
          setShowWarning(false);
          setShowExpired(false);
        }
      } catch (error) {
        console.error('Erreur lors du décodage du token:', error);
        handleLogout();
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userEmail');
      setTokenValid(false);
      setShowWarning(false);
      setShowExpired(true);
      
      // Attendre 3 secondes avant de rediriger
      setTimeout(() => {
        setShowExpired(false);
        navigate('/connect');
      }, 3000);
    };

    // Vérification immédiate
    checkTokenValidity();

    // Mise en place des vérifications périodiques
    const intervalId = setInterval(checkTokenValidity, 30000); // Vérification toutes les 30 secondes

    // Nettoyage
    return () => clearInterval(intervalId);
  }, [navigate]);

  // Rendu des notifications
  const Notifications = () => {
    if (showExpired) {
      return (
        <Alert variant="error">
          <AlertDescription>
            Votre session a expiré. Vous allez être redirigé vers la page de connexion...
          </AlertDescription>
        </Alert>
      );
    }
    
    if (showWarning) {
      return (
        <Alert variant="warning">
          <AlertDescription>
            Votre session expirera dans moins de 5 minutes. Veuillez vous reconnecter pour continuer à utiliser l'application Vidal Bravo - Jandia.
          </AlertDescription>
        </Alert>
      );
    }

    return null;
  };

  return {
    tokenValid,
    Notifications
  };
};

export default useTokenCheck;
import React, { useEffect, useState, useRef } from 'react';
import '../styles/LogoFooter.css';
import '../styles/Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
    const [serverTime, setServerTime] = useState(null);
     const [isModuleEnabled, setIsModuleEnabled] = useState(true);
  const ring1Ref = useRef(null);
  const ring2Ref = useRef(null);
  const ring3Ref = useRef(null);

   // Pour chaque fichier (Files.js, Discussion.js, etc.)
       const [modules, setModules] = useState({
         files: true,
         articles: true,
         client: true,
         discussions: true,
         media: true,
         masp: true,
         network: true,
         panel: true,
         publicMedia: true,
         autrePublication: true,
         blog: true,
         AdminDashboard: true,
         visio: true,
         faq: true,
         footer: true,
         header: true,
         draft: true
       });
   
   
   useEffect(() => {
     // Récupérer l'état depuis le localStorage
     const storedModules = localStorage.getItem('adminDashboardModules');
     if (storedModules) {
       try {
         setModules(JSON.parse(storedModules));
       } catch (error) {
         console.error('Error parsing stored modules:', error);
       }
     }
   }, []);
   
   
       useEffect(() => {
          const storedToken = localStorage.getItem('authToken');
       if (!storedToken) {
         // Handle the case where no token is stored
         return;
       }
       const checkModuleStatus = async () => {
         try {
           const response = await fetch(`/api/modules/status/footer`, {  // 'files' au lieu de :moduleName
             headers: {
               'Authorization': `Bearer ${storedToken}` // Ajout du token
             }
           });
           const { enabled } = await response.json();
           setIsModuleEnabled(enabled);
         } catch (error) {
           console.error('Erreur:', error);
         }
       };
         checkModuleStatus();
       }, []);

    useEffect(() => {
        fetch('/get-last-elapsed-time')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const serverTime = parseFloat(data.lastElapsedTime); 
          setServerTime(serverTime);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
      
      }, []);
      

      if (!isModuleEnabled) {
        return <div>Module désactivé</div>;
      }


    return (
        <footer>
        <div className='logoFooter'>Vidal Bravo - Jandia</div>
        <div className='logoFooter1'>Interactive Labs</div>
        <div className='right'>© 2025 Vidal Bravo - Jandia. All rights reserved - Propriétaire : Vidal Bravo - Jandia Miguel, 2 rue Alphonse Daudet 66000 Perpignan - France
        / Hébergement - PlanetHoster 4416 Louis-B.-Mayer Laval, Québec Canada H7P 0G1</div>
        <div className='contactfooter'>
        <Link to='/contact#contact'>
        <p className='contactez'>Contactez-nous</p>
            
                <i class="fa-regular fa-envelope"></i>
            </Link>
            </div>
            <div className="ring-container1">
    <div id="ring5" className="ring4">
      <div className="electron electron-1"></div>
      <div className="electron electron-1" style={{animationDelay: '1s'}}></div>
    </div>
    <div id="ring6" className="ring4">
      <div className="electron electron-2"></div>
      <div className="electron electron-2" style={{animationDelay: '1.5s'}}></div>
    </div>
    <div id="ring7" className="ring4">
      <div className="electron electron-3"></div>
      <div className="electron electron-3" style={{animationDelay: '2s'}}></div>
    </div>
  </div>
            <div className='right'>Données Personnelles et Confidentialité</div>
            <div className='contactfooter'>
        <Link to='/confidentialDataPolicy#confidentialDataPolicy'>
        <p className='contactez'>Politique de confidentialité et Politique de gestion des cookies</p>
            </Link>
            </div>
            <div className='right'>Vidal Bravo - Jandia Copyright</div>
            <div className='version'>
                <p className='version-footer'>- La reproduction de la base de données est strictement interdite - {serverTime !== null ? (
        <p className='propulse'> <span className='propulse'>Build 4 - V.4.4.4 - Stable - Propulsée en : {serverTime} ms</span></p>
      ) : (
        <p>Chargement...</p>
      )} - Tous droits réservés - </p>
            </div>
            <div className='footer-content'>
            <Link to='/planSite#planSite'><button type="button">Plan du Site</button></Link>
  <Link to='/propos#propos'><button type="button">A Propos</button></Link>
  <Link to='/conditionGenerales#conditiongeneral'><button type="button">Condition Générale</button></Link>
</div>

        </footer>
    );

}

export default Footer
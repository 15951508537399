import React, { useState, useRef, useEffect } from 'react';

const MobileCarousel = ({ diapo = [] }) => {
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Défilement automatique infini
  useEffect(() => {
    const timer = setInterval(() => {
      if (currentIndex === diapo.length - 1) {
        // Si on est à la dernière image, on revient à la première
        setCurrentIndex(0);
        if (carouselRef.current) {
          carouselRef.current.scrollTo({
            left: 0,
            behavior: 'smooth'
          });
        }
      } else {
        // Sinon on passe à la suivante
        setCurrentIndex(prevIndex => prevIndex + 1);
        if (carouselRef.current) {
          const itemWidth = carouselRef.current.offsetWidth;
          carouselRef.current.scrollTo({
            left: (currentIndex + 1) * itemWidth,
            behavior: 'smooth'
          });
        }
      }
    }, 5000);

    return () => clearInterval(timer);
  }, [currentIndex, diapo.length]);

  if (!diapo || diapo.length === 0) {
    return (
      <div className="carousel">
        <p>Chargement...</p>
      </div>
    );
  }

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const x = e.touches[0].clientX;
    const walk = (startX - x) * 2;
    
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollLeft + walk;
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (carouselRef.current) {
      setScrollLeft(carouselRef.current.scrollLeft);
      
      const itemWidth = carouselRef.current.offsetWidth;
      const newIndex = Math.round(carouselRef.current.scrollLeft / itemWidth);
      
      // Gestion du défilement infini pour le touch
      if (newIndex >= diapo.length) {
        setCurrentIndex(0);
        carouselRef.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      } else if (newIndex < 0) {
        setCurrentIndex(diapo.length - 1);
        carouselRef.current.scrollTo({
          left: (diapo.length - 1) * itemWidth,
          behavior: 'smooth'
        });
      } else {
        setCurrentIndex(newIndex);
      }
    }
  };

  const countNumberNext = () => {
    const nextIndex = currentIndex === diapo.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
    if (carouselRef.current) {
      const itemWidth = carouselRef.current.offsetWidth;
      carouselRef.current.scrollTo({
        left: nextIndex * itemWidth,
        behavior: 'smooth'
      });
    }
  };

  const countNumberPrev = () => {
    const prevIndex = currentIndex === 0 ? diapo.length - 1 : currentIndex - 1;
    setCurrentIndex(prevIndex);
    if (carouselRef.current) {
      const itemWidth = carouselRef.current.offsetWidth;
      carouselRef.current.scrollTo({
        left: prevIndex * itemWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="carousel">
      <div className="counter">{`${currentIndex + 1}/${diapo.length}`}</div>
      <div
        ref={carouselRef}
        className="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {diapo.map((slide, index) => (
          <div key={index} className="carousel-slide">
            <img
              src={slide}
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>
      
      <button
        className="arrowLeft"
        onClick={countNumberPrev}
      >
        &#8592;
      </button>
      <button
        className="arrowRight"
        onClick={countNumberNext}
      >
        &#8594;
      </button>
    </div>
  );
};

export default MobileCarousel;


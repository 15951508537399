// ConsentMessage.js
import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import TagManager from 'react-gtm-module';


const ConsentMessage = () => {
  const [showOptions, setShowOptions] = useState(false);

  const handleConsent = (consentGiven) => {
    if (consentGiven) {
      // Initialisez Google Tag Manager une fois que le consentement est donné
      const tagManagerArgs = {
        gtmId: 'GT-5D9R37D', // Remplacez par votre ID de conteneur
      };
      TagManager.initialize(tagManagerArgs);
    } else {
      // Gérez le cas où l'utilisateur refuse le consentement
      console.log("L'utilisateur a refusé le consentement.");
    }
  };

  return (
   <>
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        declineButtonText="Je décline"
        cookieName="ReactCookieConsent"
        expires={365}
        enableDeclineButton
        onAccept={() => handleConsent(true)}
        onDecline={() => handleConsent(false)}
        contentClasses="CookieConsent" // Assurez-vous que la classe est correcte
        buttonWrapperClasses="custom-cookie-button-wrapper"
        buttonClasses="custom-cookie-button"
      >
       
          <p>Avant de définir vos préférences, sachez que Vidal Bravo - Jandia utilise des cookies. Sans eux, on ne peut pas mesurer notre audience.</p>
          <p>Cliquez sur le bouton orange « J'accepte » si vous acceptez de nous donner une autorisation. Sinon, personnalisez vos préférences en cliquant sur le bouton « Je choisis »</p>
        
        <div>
          <button onClick={() => setShowOptions(true)} aria-label="Je choisis">Je choisis</button>
        </div>
      </CookieConsent>

      {showOptions && (
        <CookieConsent
          location="bottom"
          buttonText="Activer Google Analytics"
          declineButtonText="Je décline"
          expires={365}
          onAccept={() => {
            handleConsent(true);
            setShowOptions(false);
          }}
          onDecline={() => {
            handleConsent(false);
            setShowOptions(false);
          }}
          contentClasses="CookieConsent"
          buttonWrapperClasses="custom-cookie-button-wrapper"
          buttonClasses="custom-cookie-button"
        >
          
            <p>Avant de définir vos préférences, sachez que Vidal Bravo - Jandia utilise des cookies afin de vous proposer une expérience personnalisée.</p>
            <p> Sans eux, on ne peut pas mesurer notre audience.</p>
            <p>Google Analytics</p>
            <p>🔘 Activer</p>
          
        </CookieConsent>
       
      )}
    </>
  );
};

export default ConsentMessage;

import React, { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Profil from '../components/Profil';
import Carousel from '../components/Carousel';
import Carou1 from '../components/Carou1';
import useTokenCheck from '../components/UseTokenCheck'; 
import '../styles/Header.css';
import '../styles/Logo.css';
// Importez vos images statiques ici
import galerie from '../image/galerie.webp';
import galerie1 from '../image/galerie1.webp';
import visio from '../image/visio.webp';
import visio1 from '../image/visio1.webp';
import musique from '../image/musique.webp';
import musique1 from '../image/musique1.webp';

import logo from '../image/logo.png';
 // Ajustez le chemin selon votre structure de dossiers


function Header() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isShowing, setIsShowing] = useState(false);
  const [username, setUsername] = useState(null);
  const [query, setQuery] = useState('');  // Ajouté pour la recherche
  const { tokenValid, Notifications } = useTokenCheck();
  const [profileImage, setProfileImage] = useState(null);
  const navigate = useNavigate();
  const ring1Ref = useRef(null);
  const ring2Ref = useRef(null);
  const ring3Ref = useRef(null);
  const ring4Ref = useRef(null);



   const [isModuleEnabled, setIsModuleEnabled] = useState(true);
     // Pour chaque fichier (Files.js, Discussion.js, etc.)
         const [modules, setModules] = useState({
           files: true,
           articles: true,
           client: true,
           discussions: true,
           media: true,
           masp: true,
           network: true,
           panel: true,
           publicMedia: true,
           autrePublication: true,
           blog: true,
           AdminDashboard: true,
           visio: true,
           faq: true,
           footer: true,
           header: true,
           draft: true
         });
     
     
     useEffect(() => {
       // Récupérer l'état depuis le localStorage
       const storedModules = localStorage.getItem('adminDashboardModules');
       if (storedModules) {
         try {
           setModules(JSON.parse(storedModules));
         } catch (error) {
           console.error('Error parsing stored modules:', error);
         }
       }
     }, []);
     
     
         useEffect(() => {
            const storedToken = localStorage.getItem('authToken');
         if (!storedToken) {
           // Handle the case where no token is stored
           return;
         }
         const checkModuleStatus = async () => {
           try {
             const response = await fetch(`/api/modules/status/header`, {  // 'files' au lieu de :moduleName
               headers: {
                 'Authorization': `Bearer ${storedToken}` // Ajout du token
               }
             });
             const { enabled } = await response.json();
             setIsModuleEnabled(enabled);
           } catch (error) {
             console.error('Erreur:', error);
           }
         };
           checkModuleStatus();
         }, []);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 992);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


// Search bar
const handleSearch = (e) => {
  e.preventDefault();
  navigate(`/search?query=${query}`); // Redirection vers la page de résultats de recherche
};

 // Vérifiez si un token d'authentification est présent dans le localStorage
 const authToken = localStorage.getItem('authToken');
 console.log('Token récupéré du localStorage:', authToken);
 const isAuthenticated = authToken !== null;

 // Fonction pour gérer la déconnexion
 const handleLogout = () => {
     // Supprimez le token d'authentification du localStorage
     localStorage.removeItem('authToken');
 };

 // Fonction pour mettre à jour username depuis le composant Profil
const updateUsername = (newUsername) => {
 setUsername(newUsername);
};

// Fonction explicite pour mettre à jour l'image de profil
const handleProfileImageUpdate = (imageUrl) => {
  setProfileImage(imageUrl);
};



 useEffect(() => {
   // Si l'utilisateur est authentifié, extrayez le nom d'utilisateur du token
   if (isAuthenticated) {
     try {
       // Divisez le token en ses parties (header, payload, signature)
       const tokenParts = authToken.split('.');
       console.log('Token divisé en parties:', tokenParts);
       // Décodage de la partie payload (qui est encodée en base64)
       const decodedPayload = JSON.parse(atob(tokenParts[1]));
       console.log('Token décodé:', decodedPayload);
 
       // Vérifiez si le payload a été correctement décodé et s'il contient un nom d'utilisateur
       if (decodedPayload && decodedPayload.username) {
         setUsername(decodedPayload.username);
       }
     } catch (error) {
       console.error('Erreur lors du décodage du token :', error);
     }
   }
 }, [isAuthenticated, authToken]);
 

 useEffect(() => {
   // Rafraîchir la liste des fichiers après le téléchargement réussi
   const refreshInterval = setInterval(() => {
       // Vérifiez si l'utilisateur est authentifié
       if (isAuthenticated && username) {
           // Utilisez le username mis à jour ici
           console.log(`L'utilisateur ${username} est authentifié...`);
       } else {
           console.log('L\'utilisateur n\'est pas authentifié, le rafraîchissement automatique est désactivé.');
       }
   }, 5000);
 
   return () => {
     clearInterval(refreshInterval);
   };
}, [isAuthenticated, username]); // username doit être inclus ici



const logout = async () => {
  const token = localStorage.getItem('authToken');
  try {
    await fetch('/logout', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    localStorage.removeItem('authToken');
    navigate('/');
    window.location.reload();
  } catch (error) {
    console.error('Erreur déconnexion:', error);
  }
};
  // Définissez le tableau statique des images pour le Carousel
  const carouselImages = [galerie, musique, visio];
  const carouImages1 = [visio1, galerie1, musique1];

  const rings = document.querySelectorAll('.ring');
    


  if (!isModuleEnabled) {
    return <div>Module désactivé</div>;
  }


  return (
    <>
      <header className='header'>
        
      <Notifications />

      <Link to='/' className='link'>
  <span className='logo'>Vidal Bravo - Jandia</span>
  <span className='logo3'>Interactive Labs</span>
  <span className='logo2'>Virtual Access Network Interactive Local Area</span>
  <span className='logo2'>« V. A. N. I. L. L. A. »</span>
</Link>
      <div className='cadreExcellence'>
       

      <nav className="nav">
            <Link to="/"><button type="button">
  <svg width="32" height="32" viewBox="0 0 24 24" fill="none" style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg">
   
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="currentColor"/>
  </svg>
  Accueil
</button>
</Link>
            {isAuthenticated ? (
              <>
                <button type="button" className="green" onClick={logout}>
                  {username
                    ? `Bienvenue, ${username}, vous êtes maintenant connecté(e) - Déconnexion`
                    : 'Bienvenue, vous êtes maintenant connecté(e) - Déconnexion'}
                  {profileImage && (
                    <img
                      src={profileImage}
                      alt="Photo de profil"
                      className="profile-image-header"
                      style={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '10%',
                        objectFit: 'cover'
                      }}
                    />
                  )}
                </button>
              </>
            ) : (
              <Link to="/connect#login">
                <button type="button">
  <svg width="32" height="32" viewBox="0 0 24 24" fill="none" style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg">
   
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="currentColor"/>
  </svg>
  Se Connecter
</button>
              </Link>
            )}
          </nav>

               
          <Link to="/" className="linklogo">
       
          <div className="logo-container">
    <div id="ring1" className="ring"><span className='racine'>√4</span>
      <div className="electron electron-1"></div>
      <div className="electron electron-1" style={{animationDelay: '1s'}}></div>
    </div>
    <div id="ring2" className="ring"><span className='racine'>π R²</span>
      <div className="electron electron-2"></div>
      <div className="electron electron-2" style={{animationDelay: '1.5s'}}></div>
    </div>
    <div id="ring3" className="ring"><span className='racine'>E = Mc4</span>
      <div className="electron electron-3"></div>
      <div className="electron electron-3" style={{animationDelay: '2s'}}></div>
    </div>
    <div id="ring4" className="ring"><span className='racine1'>1 + 1 = 3</span>
      <div className="electron electron-4"></div>
      <div className="electron electron-4" style={{animationDelay: '2s'}}></div>
    </div>
  </div>

    </Link>
  
  <div className='dessin10'>
  <Link to='/resultSearch#resultsearch'><button type="button">
  <svg width="32" height="32" viewBox="0 0 24 24" fill="none" style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg">
    
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="currentColor"/>
  </svg>
  Rechercher
</button></Link>
       
          <p className="phone">
            <a href="tel:+33649880958" className='tel'>
            <svg width="32" height="32" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg">
    <path d="M6.62 10.79c1.17 2.17 2.98 3.98 5.15 5.15l2.09-2.09c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.62.57.83 0 1.5.67 1.5 1.5v3c0 .83-.67 1.5-1.5 1.5C9.92 20.18 2 12.27 2 2.5 2 1.67 2.67 1 3.5 1h3c.83 0 1.5.67 1.5 1.5 0 1.29.2 2.5.57 3.62.12.35.03.75-.24 1.02l-2.09 2.09z"/>
  </svg>
              Appeler Du lundi au vendredi de 9h à 19h</a>
          </p>
         

        </div> 
        
        </div>

        
          
        <div className='monMurTitle1'>
          <span className='accueil'>« Système de création de contenu et de partage »</span>
          <br/><span className='expert'>« Auteurs, Experts, Enseignants, Formateurs, Collaborateurs ou Etudiants : Etablissez votre réseau - Etendez-le... »</span>
          <br/>« Hébergez vos créations, contenus et activités »
        </div>
        {isDesktop ? (
          <Carousel slides={carouselImages} />
        ) : (
          <Carou1 diapo={carouImages1} />
        )}
        
       

      </header>
      {isAuthenticated && (
        <Profil 
          updateProfileImage={handleProfileImageUpdate}
          updateUsername={setUsername}
        />
      )}
    </>
  );
}

export default Header;
import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Error from "./routes/Error";
import AuthGuard from "./components/AuthGuard";
import ConsentMessage from "./components/ConsentMessage";
import './styles/Global.css';
import './styles/Article.css';
import './styles/Draft.css';
import './styles/Blog.css';
import './styles/Carousel.css';
import './styles/Client.css';
import './styles/Client2.css';
import './styles/ConsentMessage.css';
import './styles/Contact.css';
import './styles/Discussion.css';
import './styles/Dropzone.css';
import './styles/Faq.css';
import './styles/File-main.css';
import './styles/Footer.css';
import './styles/Header.css';
import './styles/Login.css';
import './styles/Masp.css';
import './styles/Network.css';
import './styles/NotFound.css';
import './styles/Panel.css';
import './styles/Profil.css';
import './styles/Propos.css';
import './styles/Reset.css';
import './styles/Tab.css';
import './styles/UserTables.css';
import './styles/ConditionGeneral.css';
import './styles/Media.css';
import './styles/PublicMedia.css';
import './styles/Search.css';
import './styles/Chat.css';
import './styles/AdminDashboard.css';
import './styles/CardsTabsSwitch.css';
import './styles/Managers.css';
import './styles/Visio.css';
import './styles/PersonalSpace.css';
import './styles/EnteteSpace.css';
import './styles/PiedSpace.css';
import './styles/Logo.css';
import './styles/PersonalSpace.css';
import './styles/PiedSpace.css'
import './styles/EnteteSpace.css'
import './styles/ModularPublicMediaComponent.css'
import './styles/ModularBlogComponent.css'
import './styles/ModularAutrePublicationComponent.css'
import './styles/ModularClientComponent.css'
import './styles/PublicSpaceList.css';
import './styles/PlanSite.css';

// Importez les composants avec Lazy Loading
const Home = lazy(() => import("./routes/Home"));
const ConditionGenerales = lazy(() => import("./routes/ConditionGenerales"));
const Propos = lazy(() => import("./routes/Propos"));
const Media = lazy(() => import("./routes/Media"));
const ResultSearch = lazy(() => import('./routes/ResultSearch'));
const PublicMedia = lazy(() => import("./routes/PublicMedia"));
const ConfidentialDataPolicy = lazy(() => import("./routes/ConfidentialDataPolicy"));
const Flux = lazy(() => import("./routes/Flux"));
const PublicInternetArticle = lazy(() => import("./routes/PublicInternetArticle"));
const Connect = lazy(() => import("./routes/Connect"));
const Register = lazy(() => import("./routes/Register"));
const Contact = lazy(() => import("./routes/Contact"));
const PublicArticle = lazy(() => import("./routes/PublicArticle"));
const ResetPassword = lazy(() => import("./routes/ResetPassword"));
const RequestReset = lazy(() => import("./routes/RequestReset"));
const Client = lazy(() => import("./routes/Client"));
const Faq = lazy(() => import("./routes/Faq"));
const AutrePublication = lazy(() => import("./routes/AutrePublication"));
const BlogGeneral2 = lazy(() => import("./routes/BlogGeneral2"));
const Files = lazy(() => import("./routes/Files"));
const UserTables = lazy(() => import("./components/UserTables"));
const Masp = lazy(() => import("./routes/Masp"));
const Blog = lazy(() => import("./routes/Blog"));
const Network = lazy(() => import("./routes/Network"));
const Panel = lazy(() => import("./routes/Panel"));
const Draft = lazy(() => import("./routes/Draft"));
const Article = lazy(() => import("./routes/Article"));
const Discussion = lazy(() => import("./routes/Discussion"));
const AdminDashboard = lazy(() => import("./routes/AdminDashboard"));
const Managers = lazy(() => import("./routes/Managers"));
const Visio = lazy(() => import("./routes/Visio"));
const PersonalSpace = lazy(() => import("./routes/PersonalSpace"));
const PersonalSpaceAdmin = lazy(() => import("./routes/PersonalSpaceAdmin"));
const PublicListSpace = lazy(() => import("./routes/PublicListSpace"));
const PlanSite = lazy(() => import("./routes/PlanSite"));
const DashBoardSuperAdmin = lazy(() => import("./routes/DashBoardSuperAdmin"));
const Negociation = lazy(() => import("./routes/Negociation"));
const Synthe = lazy(() => import("./routes/Synthe"));
const helmetContext = {};



const App = () => {

  useEffect(() => {
    // Chargez dynamiquement le script gtag.js
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-6NRSFFBGM4";
    document.head.appendChild(script);

    script.onload = () => {
      // Initialisez Google Analytics une fois que le script est chargé
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-6NRSFFBGM4");
    };

    // Nettoyez le script lors du démontage du composant
    return () => {
      document.head.removeChild(script);
    };
  }, []);


  const ScrollToAnchor = () => {
    const { hash } = useLocation();
  
    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }, [hash]);
  
    return null;
  };

  return (
    <Router>
       <ScrollToAnchor />
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" element={<Home />} />
        <Route path="/propos" element={<Propos />} />
        <Route path="/publicListSpace" element={<PublicListSpace />} />
        <Route path="/resultSearch" element={<ResultSearch />} />
        <Route path="/publicMedia" element={<PublicMedia />} />
        <Route path="/confidentialDataPolicy" element={<ConfidentialDataPolicy />} />
        <Route path="/flux" element={<Flux />} />
        <Route path="/blogGeneral2" element={<BlogGeneral2 />} />
        <Route path="/publicInternetArticle" element={<PublicInternetArticle />} />
        <Route path="/conditionGenerales" element={<ConditionGenerales />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/requestReset" element={<RequestReset />} />
        <Route path="/planSite" element={<PlanSite />} />
        <Route path="/client" element={<AuthGuard><Client /></AuthGuard>} />
        <Route path="/media" element={<AuthGuard><Media /></AuthGuard>} />
        <Route path="/faq" element={<AuthGuard><Faq /></AuthGuard>} />
        <Route path="/resultSearch" element={<AuthGuard><ResultSearch /></AuthGuard>} />
        <Route path="/autrePublication" element={<AuthGuard><AutrePublication /></AuthGuard>} />
        <Route path="/files" element={<AuthGuard><Files /></AuthGuard>} />
        <Route path="/userTables" element={<AuthGuard><UserTables /></AuthGuard>} />
        <Route path="/masp" element={<AuthGuard><Masp /></AuthGuard>} />
        <Route path="/blog" element={<AuthGuard><Blog /></AuthGuard>} />
        <Route path="/network" element={<AuthGuard><Network /></AuthGuard>} />
        <Route path="/panel" element={<AuthGuard><Panel /></AuthGuard>} />
        <Route path="/adminDashboard" element={<AuthGuard><AdminDashboard /></AuthGuard>} />
        <Route path="/draft" element={<AuthGuard><Draft /></AuthGuard>} />
        <Route path="/article" element={<AuthGuard><Article /></AuthGuard>} />
        <Route path="/synthe" element={<AuthGuard><Synthe /></AuthGuard>} />
        <Route path="/publicArticle" element={<AuthGuard><PublicArticle /></AuthGuard>} />
        <Route path="/discussion" element={<AuthGuard><Discussion /></AuthGuard>} />
        <Route path="/managers" element={<AuthGuard><Managers /></AuthGuard>} />
        <Route path="/negociation/:sessionId" element={<AuthGuard><Negociation /></AuthGuard>} />
        <Route path="/dashBoardSuperAdmin" element={<AuthGuard><DashBoardSuperAdmin /></AuthGuard>} />
        <Route path="/visio" element={<AuthGuard><Visio /></AuthGuard>} />   
        <Route path="/personalSpace/:slug" element={<PersonalSpace />} />
        <Route path="/personalSpace/:slug/admin" element={<AuthGuard><PersonalSpaceAdmin /></AuthGuard>} />
      </Routes>
    </Router>
  
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>  {/* Enveloppe ton application avec HelmetProvider */}
      <ConsentMessage />
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </HelmetProvider>
  </React.StrictMode>
);

export default App;

import * as React from "react";
import '../styles/AlertTokenExpires.css';

const Alert = React.forwardRef(({ className, variant = 'default', ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={`alert alert-${variant} ${className || ''}`}
    {...props}
  />
));

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`alert-description ${className || ''}`}
    {...props}
  />
));

export { Alert, AlertDescription };